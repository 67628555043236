import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, Col } from 'reactstrap'
import ComponentCard from '../../layouts/containers/ComponentCard'
import PestañasDatos from './PestañasDatos';
import Matriculaciones from './Matriculaciones';
import Ciclos from './Ciclos'

const Detalles = () => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const { id, empresaId } = JSON.parse(sessionStorage.getItem("student"))
    const { email } = JSON.parse(sessionStorage.getItem("user")).profileObj
    const [dataApi, setDataApi] = useState('')
    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}detalleAlumno.php?empresaId=${empresaId}&id=${id}&email=${email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json'
            }
        }
        )
            .then((response) => response.json())
            .then((actualData) => {
                if (actualData.error === "Unauthorized") {
                    const error = { error: actualData.error }
                    sessionStorage.setItem('error', JSON.stringify(error))
                    window.location.reload()
                } else {
                    setDataApi(actualData)
                }
            })

    }, []);

    return (
        <>
            <Col md="12">
                <ComponentCard title="Gestión de usuario">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? 'active' : ''}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Datos Alumno
                            </NavLink>
                        </NavItem>
                        {dataApi !== '' && dataApi.datos.tutores[0].parentesco === '(Sin Tutores)' ? '' : <NavItem>
                            <NavLink
                                className={activeTab === '2' ? 'active' : ''}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Datos tutores
                            </NavLink>
                        </NavItem>}

                    </Nav>
                    <PestañasDatos activeTab={activeTab} dataApi={dataApi} />
                </ComponentCard>
                {dataApi !== '' && dataApi.matriculas.length > 0 ? <Matriculaciones matriculas={dataApi.matriculas} /> : ''}

                {dataApi !== '' && dataApi.ciclos.length > 0 ? <Ciclos ciclos={dataApi.ciclos} /> : ''}
            </Col>

        </>
    )
}

export default Detalles