import { TabContent } from 'reactstrap'
import PropTypes from 'prop-types'
import DatosAlumnos from "./DatosAlumnos"
import Loader from '../../layouts/loader/Loader'
import DatosTutores from "./DatosTutores"


const PestañasDatos = (props) => {
    return (
        <>
            {props.dataApi === ('') ? <Loader /> :
                props.dataApi.datos === null ? <p className="mt-2 text-danger"><strong>Este alumno no dispone de datos.</strong></p> : <TabContent className="p-4" activeTab={props.activeTab}>
                    <DatosAlumnos datosAlumno={props.dataApi} />
                    <DatosTutores datosTutor={props.dataApi} />
                </TabContent>
            }

        </>


    )
}

PestañasDatos.propTypes = {
    activeTab: PropTypes.string,
    dataApi: PropTypes.any
};
export default PestañasDatos