import { TabPane, Row, Col, Input, Form, Label } from 'reactstrap'
import PropTypes from 'prop-types'

const DatosAlumnos = (props) => {
    return (


        <TabPane tabId="1">
            <Form>
                <Row>
                    <Col sm="2" className="mt-1 mt-md-0">
                        <Label htmlFor="idalu"><small>IDALU</small></Label>
                        <Input type="text" name="idalu" id="idalu" value={props.datosAlumno.datos.personales.idalu === null ? '' : props.datosAlumno.datos.personales.idalu} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-0">
                        <Label htmlFor="name"><small>Nombre</small></Label>
                        <Input type="text" name="name" id="name" value={props.datosAlumno.datos.personales.nombre === null ? '' : props.datosAlumno.datos.personales.nombre} disabled />
                    </Col>
                    <Col sm="5" className="mt-1 mt-md-0">
                        <Label htmlFor="lastname" className=""><small>Apellidos</small></Label>
                        <Input type="text" name="apellidos" id="lastname" value={props.datosAlumno.datos.personales.apellidos === null ? '' : props.datosAlumno.datos.personales.apellidos} disabled />
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="dni"><small>DNI</small></Label>
                        <Input type="text" name="dni" id="dni" value={props.datosAlumno.datos.personales.dni === null ? '' : props.datosAlumno.datos.personales.dni} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="city"><small>Población</small></Label>
                        <Input type="text" name="city" id="city" value={props.datosAlumno.datos.personales.poblacion === null ? '' : props.datosAlumno.datos.personales.poblacion} disabled />
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="born"><small>Lugar de nacimiento</small></Label>
                        <Input type="text" name="born" id="born" value={props.datosAlumno.datos.personales.lugarnac === null ? '' : props.datosAlumno.datos.personales.lugarnac} disabled />
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="birthday"><small>Fecha de nacimiento</small></Label>
                        <Input type="text" name="birthday" id="birthday" value={props.datosAlumno.datos.personales.fechanac === null ? '' : props.datosAlumno.datos.personales.fechanac} disabled />
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="cp"><small>Código postal</small></Label>
                        <Input type="text" name="cp" id="cp" value={props.datosAlumno.datos.personales.cp === null ? '' : props.datosAlumno.datos.personales.cp} disabled />
                    </Col>
                    <Col sm="4" className="mt-1 mt-md-1">
                        <Label htmlFor="street"><small>Dirección</small></Label>
                        <Input type="text" name="street" id="street" value={props.datosAlumno.datos.personales.direccion === null ? '' : props.datosAlumno.datos.personales.direccion} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="email1"><small>Email 1</small></Label>
                        <Input type="text" name="email1" id="email1" value={props.datosAlumno.datos.personales.email1 === null ? '' : props.datosAlumno.datos.personales.email1} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="email2"><small>Email 2</small></Label>
                        <Input type="text" name="email2" id="email2" value={props.datosAlumno.datos.personales.email2 === null ? '' : props.datosAlumno.datos.personales.email2} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="phone"><small>Teléfono 1</small></Label>
                        <Input type="text" name="phone" id="phone" value={props.datosAlumno.datos.personales.telefono1 === null ? '' : props.datosAlumno.datos.personales.telefono1} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="phone2"><small>Teléfono 2</small></Label>
                        <Input type="text" name="phone2" id="phone2" value={props.datosAlumno.datos.personales.telefono2 === null ? '' : props.datosAlumno.datos.personales.telefono2} disabled />
                    </Col>

                </Row>
                <Row>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="nss"><small>Número seguridad social</small></Label>
                        <Input type="text" name="nss" id="nss" value={props.datosAlumno.datos.personales.nsegsoc === null ? '' : props.datosAlumno.datos.personales.nsegsoc} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="healthcare"><small>Tarjeta sanitaria</small></Label>
                        <Input type="text" name="healthcare" id="healthcare" value={props.datosAlumno.datos.personales.ntsi === null ? '' : props.datosAlumno.datos.personales.ntsi} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="studies"><small>Estudios anteriores</small></Label>
                        <Input type="text" name="studies" id="studies" value={props.datosAlumno.datos.personales.estudantnom === null ? '' : props.datosAlumno.datos.personales.estudantnom} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="datestudies"><small>Fecha de los últimos estudios</small></Label>
                        <Input type="text" name="datestudies" id="datestudies" value={props.datosAlumno.datos.personales.estudantffin === null ? '' : props.datosAlumno.datos.personales.estudantffin} disabled />
                    </Col>
                </Row>
            </Form>

        </TabPane>
    )
}
DatosAlumnos.propTypes = {
    datosAlumno: PropTypes.any
};
export default DatosAlumnos