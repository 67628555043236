import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import LoginForm from '../../components/login/LoginForm';
import logo from '../../assets/images/logos/logo.png';
import ModalSesion from '../../layouts/modal/ModalSession';
import { ReactComponent as LeftBg } from '../../assets/images/bg/login-bgleft.svg';
import { ReactComponent as RightBg } from '../../assets/images/bg/login-bg-right.svg';
import '../../assets/scss/style.scss';

const Login = (props) => {
  return (
    <>
      <div className="loginBox">
        {props.sesionExpired === 'true' ? (
          <ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" />
        ) : (
          ''
        )}
        <LeftBg className="position-absolute left bottom-0" />
        <RightBg className="position-absolute end-0 top" />
        <Container fluid className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg="12" className="loginContainer">
              <Card className="text-center">
                <img src={logo} alt="Imagen corporativa" width="300" className="mx-auto" />
                <CardBody className="p-4 m-1">
                  <span className="pb-4 d-block">Seleccione el centro. CEDESCA / CNTEC</span>
                  <small className="pb-4 d-block">
                    Recuerde usar su cuenta de <b>cedetecgroup.com</b>
                  </small>
                  <Row>
                    <LoginForm />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
Login.propTypes = {
  sesionExpired: PropTypes.string,
};
export default Login;
