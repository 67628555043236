import React from 'react';

const ProfileDD = () => {
  const { name, email, imageUrl } = JSON.parse(sessionStorage.getItem("user")).profileObj
  return (
    <div>
      <div className="d-flex gap-3 p-3 border-bottom pt-2 align-items-center">
        <img src={imageUrl} alt="user" className="rounded-circle" width="60" />
        <span>
          <h6 className="mb-0">{name}</h6>
          <small>{email}</small>
        </span>
      </div>
    </div>
  );
};

export default ProfileDD;
