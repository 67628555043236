/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
//import React, { Suspense } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import Login from './views/login/Login'
import ModalSesion from "./layouts/modal/ModalSession"
import DetallesUsuario from './views/details/DetallesUsuario';
import { AuthContext } from './context/authContext'
/*import Loader from './layouts/loader/Loader';
import FullLayout from './layouts/FullLayout'*/

const App = () => {
  const { logoutGoogle } = useContext(AuthContext)
  const userSession = JSON.parse(sessionStorage.getItem('user'))
  const [isVerified, setIsVerified] = useState(1)

  let render = null

  const onLoad = (verified) => {

    if (sessionStorage.getItem('user') === null) {
      render = <Login />
    } else if (userSession !== null) {
      if (verified === 0) {
        logoutGoogle()
        render = <><ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" /><Login /></>
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('student')
      } else if (JSON.parse(sessionStorage.getItem('error')) !== null) {
        logoutGoogle()
        render = <><ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" /><Login /></>
      } else {
        render = <DetallesUsuario />
      }

    }

    return render
  }

  useEffect(() => {

    if (userSession !== null) {
      const token = userSession.tokenId

      const formData = new FormData()
      formData.append('tokenId', token)

      fetch(`${process.env.REACT_APP_URL_API}verifyToken.php`, {
        method: 'POST',
        body: formData,
        redirect: 'follow',
        mode: 'cors',
        cache: "no-cache",
        referrerPolicy: "no-referrer",
        credentials: "same-origin"

      }
      )
        .then((response) => response.json())
        .then((actualData) => setIsVerified(actualData.code))
    }


  }, [])
  return (
    <>
      {onLoad(isVerified)}
    </>
  );
};

export default App;